<template>
  <div>
    <titleBar
      :title="'Edit Profile'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :isChild="true"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800" style="max-width: 900px">
        <h2 class="title is-4">Next steps for you</h2>

        <div class="columns is-multiline">
          <div class="column is-4" v-for="(card, index) in nextStepsCards" :key="card.title + index">
            <div class="box" :style="{ color: card.color }">
              <b-icon :icon="card.icon" size="is-large" pack="fal"></b-icon>
              <h4 class="title is-6">{{ card.title }}</h4>
              <p>{{ card.description }}</p>
              <!--
              <router-link :to="card.btTo">
                <b-button type="is-da is-small is-outlinedNO">{{ card.btLabel }}</b-button>
              </router-link> -->
            </div>
          </div>
        </div>

        <h2 class="title is-4">Bot basics</h2>

        <headerManage
          title="Access"
          :sub="bot.visibilityMode"
          desc="Who can find your bot and interact with it"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontalNO label="">
              <div>
                <b-radio v-model="bot.visibilityMode" native-value="private" type="is-info" :disabled="posting">
                  Private (only you)
                </b-radio>
                <br />
                <b-radio v-model="bot.visibilityMode" native-value="public" type="is-info" :disabled="posting">
                  Public (anyone can discover it)
                </b-radio>

                <!-- 
                         <b-radio v-model="bot.visibilityMode" native-value="secret" type="is-info" :disabled="posting">
                  Secret URL <span class="tag is-warning is-light">Premium</span>
                </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="pro" type="is-info" :disabled="posting"> Pro </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Subscribers (paid members only)
            <span class="tag is-info is-light">Premium</span>
          </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Unlimited <span class="tag is-info is-light">Premium</span>
          </b-radio>
           -->
              </div>
            </b-field>

            <b-field horizontalNO v-show="bot.visibilityMode == 'public'">
              <b-switch v-model="bot.nsfw" type="is-dark">
                NSFW
                <b-tooltip
                  type="is-dark"
                  multilined
                  label="If your content is not suitable for all audiences, you can mark it as NSFW. AI models aren't suited to generate explicit or inappropriate content. Circumventing this filter is not allowed."
                >
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field>

            <b-field horizontalNO v-show="bot.visibilityMode == 'public'">
              <b-switch v-model="bot.verifiedbadge" type="is-dark">
                Verified badge
                <b-tooltip type="is-dark" multilined label="Display a blue tick next to your page name in listings.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span>
              </b-switch>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Plan"
          sub="Pay as you go  "
          btLabel="Compare Plans"
          :btTo="bot.url + '/manage/plans'"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p>This is some custom content for the slot.</p>
          </div>
        </headerManage>

        <headerManage
          title="Members"
          sub="Only you  "
          desc="Select users who can edit this bot or access it freely."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field horizontalNO label="Owner">
              <b-taginput
                v-model="bot.owner"
                :disabled="true"
                ellipsis
                placeholder=" "
                aria-close-label="Delete this tag"
              >
              </b-taginput>
            </b-field>

            <b-field horizontalNO label="Admins">
              <b-taginput
                v-model="bot.admins"
                ellipsis
                placeholder=" bob, steve, etc"
                aria-close-label="Delete this tag"
              >
              </b-taginput>
            </b-field>

            <b-field horizontalNO label="Members">
              <b-taginput
                v-model="bot.members"
                ellipsis
                placeholder=" bob, steve, etc"
                aria-close-label="Delete this tag"
              >
              </b-taginput>
            </b-field>

            <p>This is some custom content for the slot.</p>
          </div>
        </headerManage>

        <h2 class="title is-4">Monetize</h2>

        <headerManage
          title="Subscriptions "
          sub="Not set - anyone can access your bot for free."
          desc="Monetize your bot with subscriptions."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <!-- 
            <b-field horizontalNO>
              <b-switch v-model="bot.subEnabled" type="is-info">
                Enable paid subscriptions
                <b-tooltip
                  type="is-dark"
                  multilined
                  label="If your content is not suitable for all audiences, you can mark it as NSFW. AI models aren't suited to generate explicit or inappropriate content. Circumventing this filter is not allowed."
                >
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field> -->
            <br />

            <b-field horizontalNO label="">
              <div>
                <b-radio v-model="bot.subEnabled" :native-value="false" type="is-info" :disabled="posting">
                  Free. Users can use this bot for free.
                </b-radio>
                <br />

                <b-radio v-model="bot.subEnabled" :native-value="true" type="is-info" :disabled="posting">
                  Paid. Users need to purchase a subscription.
                </b-radio>
              </div>
            </b-field>

            <div v-show="bot.subEnabled">
              <b-field label="Monthly price to fully unlock this bot">
                <b-select placeholder="Select a price" v-model="bot.subPrice" :disabled="!bot.subEnabled">
                  <option v-for="price in prices" :key="price" :value="price">{{ price }} coins</option>
                </b-select>
              </b-field>
              <span v-if="bot.subPrice" class="small muted"> (that's about {{ aproxPriceStr }}/m for subscribers)</span>

              <!--  
            <b-field horizontalNO label="Monthly price">
              <b-input
                custom-class=""
                v-model="bot.website"
                :loading="posting"
                :disabled="posting"
                type="number"
              ></b-input>
            </b-field>-->

              <b-field horizontalNO>
                <b-switch v-model="bot.subTrialEnabled" type="is-info">
                  Provide free trial
                  <b-tooltip
                    type="is-dark"
                    multilined
                    label="Between 3 and 20 messages, depending on the model used. This improves the reach of your bot, while limiting risks of abuses. "
                  >
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                </b-switch>
              </b-field>
            </div>
            <!--  

            <b-field label="Trial">
              <b-checkbox v-model="bot.subTrialEnabled">Enable free trial </b-checkbox>
            </b-field>

            <b-field label="">
              <b-checkbox v-model="checkbox"
                >I allow my bot to be included bundles (bundle usage won't incur any cost and revenues are shared
                amongst creators)</b-checkbox
              >
            </b-field>

            <p>Price, checkbox Offer a free 10 messages trial</p> -->
          </div>
        </headerManage>

        <headerManage
          title="Donations"
          sub="Not set  "
          desc="Invite users to give a tip to your bot"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field horizontalNO>
              <b-switch v-model="bot.tipsEnabled" type="is-info">
                Enable tips for this bot
                <b-tooltip
                  type="is-dark"
                  multilined
                  label=" This is a less aggressive monetization method than subscriptions and is a great fir for more occasional bots. "
                >
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field>
          </div>
        </headerManage>

        <h2 class="title is-4">Profile</h2>

        <headerManage
          title="Bio"
          :sub="bot.bio"
          @save="save"
          desc="One line bio, shown on lists and the bot profile"
          :posting="posting"
        >
          <div>
            <b-field horizontalNO label="">
              <b-input
                type="textarea"
                placeholder="Hi!  I chat and I know things."
                v-model="bot.bio"
                :loading="posting"
                :disabled="posting"
                :maxlength="150"
              ></b-input>
            </b-field>

            <b-field horizontalNO label="Tags">
              <b-taginput
                v-model="bot.tags"
                ellipsis
                placeholder=" utility, dating, marketing"
                aria-close-label="Delete this tag"
              >
              </b-taginput>
            </b-field>

            <b-field horizontalNO label="Main category">
              <b-select v-model="bot.category" :disabled="posting">
                <option :value="c.value" v-for="c in cats" :key="c.value">{{ c.label }}</option>
              </b-select>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Link"
          :sub="bot.website ? bot.website : 'Not set'"
          desc="Display a link to your website or other social media profile"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="Website">
              <b-input
                size="is-"
                placeholder="https://myapp.com/developpers"
                v-model="bot.website"
                :loading="posting"
                :disabled="posting"
                type="url"
              ></b-input>
            </b-field>

            <b-field horizontal label=" Twitter  ">
              <b-input
                size="is-"
                placeholder="myAiProject"
                v-model="bot.twitter"
                :loading="posting"
                :disabled="posting"
              ></b-input>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Disclaimer"
          :sub="bot.disclaimer ? bot.disclaimer : 'Not set'"
          @save="save"
          desc="Optional. Provide a disclaimer for your bot, so user aren't confused about what it can do, it's limitations, and how it works."
          :posting="posting"
        >
          <div>
            <b-field horizontalNO label="">
              <b-input
                type="textarea"
                placeholder="Hi!  I chat and I know things."
                v-model="bot.disclaimer"
                :loading="posting"
                :disabled="posting"
                :maxlength="150"
              ></b-input>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Profile picture"
          :sub="bot.avatar ? '🖼️ Looking good!' : 'Not set'"
          desc="Choose an avatar"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontalNO label="Image URL ">
              <b-input
                size="is-"
                placeholder="https://myapp.com/img/logo.png"
                v-model="bot.avatar"
                :loading="posting"
                :disabled="posting"
                type="url"
              ></b-input>
            </b-field>
            <b-field horizontalNO v-if="bot.avatar">
              <img :src="bot.avatar" class="avatar" style="width: 150px; height: 150px; border-radius: 50%" />
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Accent color"
          :sub="bot.bubbleColor1 ? accentColorHex : 'Not set'"
          desc="Choose an avatar"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field label="Chatb bubllbes  color">
              <b-colorpicker v-model="bot.bubbleColor1" :alpha="false" />
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Readme "
          :sub="bot.readmeHtml ? 'Edit readme instructions  ' : 'No readme instructions for this bot'"
          :btLabel="bot.readmeHtml ? 'Edit' : 'Add a readme  '"
          :btTo="bot.url + '/manage/readme'"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p>This is some custom content for the slot.</p>
          </div>
        </headerManage>

        <headerManage
          title="Profite tabs"
          sub="Edit featured tabs"
          desc="Select which content is featured on your profile"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontalNO>
              <b-switch v-model="bot.tabReviews" type="is-info">
                User reviews
                <b-tooltip
                  type="is-dark"
                  multilined
                  label=" Let users review my bot, and display these on the bot profile. This improves credibility and trust of your bot, especially if you offer paid subscriptions. "
                >
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field>

            <b-field horizontalNO>
              <b-switch v-model="bot.tabGrid" type="is-info">
                Photo grid
                <b-tooltip
                  type="is-dark"
                  multilined
                  label=" Let users review my bot, and display these on the bot profile. This improves credibility and trust of your bot, especially if you offer paid subscriptions. "
                >
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field>

            <b-field horizontalNO>
              <b-switch v-model="bot.tabPosts" type="is-info">
                Posts Feed
                <b-tooltip type="is-dark" multilined label="  Feed of all posts from this bot. ">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field>

            <b-field horizontalNO>
              <b-switch v-model="bot.tabTexts" type="is-info">
                Text updates
                <b-tooltip type="is-dark" multilined label="  Feed of all posts from this bot. ">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field>

            <b-field horizontalNO>
              <b-switch v-model="bot.tabArticles" type="is-info">
                Articles
                <b-tooltip type="is-dark" multilined label=" Published articles ">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field>

            <b-field horizontal label="Default tab ">
              <b-select v-model="bot.firstTab" :disabled="posting">
                <option value="auto">Automatic</option>
                <option value="mosaic">Mosaic</option>
                <option value="feed">Feed</option>
                <!--  
            <option value="examples">Examples</option> -->
                <option value="readme">Readme</option>
                <option value="reviews">Reviews</option>
              </b-select>
            </b-field>
          </div>
        </headerManage>

        <!--  
        <headerManage
          title="Base language model"
          sub="Fast (GPT3)"
          desc="Pick the engine that works best for your bot. "
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="Engine">
              <b-select
                v-if="bot.messagingMode == 'simple'"
                v-model="bot.messagingEngine"
                placeholder="Select engine"
                :disabled="posting"
              >
                <option value="gpt3">Default (speed-optimized GPT3.5)</option>
                <option value="gpt4">Smart (slower & pricier GPT4)</option>
               
              </b-select>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Custom OpenAI key"
          sub="Not set "
          desc="Use a personal openAI key for this bot"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="API key">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.openAiKey"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>
            <OtherComponent />
          </div>
        </headerManage>-->

        <!--    <h2 class="title is-4">Access and monetization</h2>

      START-->

        <!-- END     

        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

            -->
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/e/EditorV";
import headerManage from "@/components/studio/headerManage";
import cats from "@/data/categories.js";

import { convertToHex } from "@/libs/color.js";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
    headerManage,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cats,
      prices: this.generatePrices(),
      // readmeJson: defaultReadme, // "432432432",
    };
  },

  computed: {
    aproxPriceUSD: function () {
      let price = this.bot.subPrice;
      if (!price) return "";
      console.log("price", price);
      // return price;
      return price * 0.0129;
    },
    aproxPriceStr: function () {
      let amount = this.aproxPriceUSD;

      var a = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
      }).format(amount);
      return a;
    },
    accentColorHex: function () {
      let color = this.bot.bubbleColor1;
      if (!color) return "";
      return convertToHex(color);
    },

    nextStepsCards: function () {
      let cards = [
        {
          title: "Enable monetization",
          description: "Monetize your content and earn money.",
          color: "#f1c40f", // Yellow
          btTo: "/enable-monetization",
          btLabel: "Monetize",
          icon: "fas fa-dollar-sign",
        },
        {
          title: "Finish setting up the profile",
          description: "Complete your profile to fully engage with the community.",
          color: "#34495e", // Dark Blue
          btTo: "/finish-profile",
          btLabel: "Complete Profile",
          icon: "fas fa-user-circle",
        },

        {
          title: "Upgrade your plan",
          description: "Access exclusive features with a premium plan.",
          color: "#2ecc71", // Green
          btTo: "/upgrade-plan",
          btLabel: "Upgrade",
          icon: "fas fa-arrow-up",
        },
        /*
        {
        title: "Enable messaging",
          description: "Communicate with others directly on our platform.",
          color: "#e74c3c", // Red
          btTo: "/enable-messaging",
          btLabel: "Enable Messaging",
          icon: "fas fa-comments",
        },
        {
          title: "Refer friends",
          description: "Invite your friends and gain benefits!",
          color: "#3498db", // Light blue
          btTo: "/refer-friends",
          btLabel: "Refer Now",
          icon: "fas fa-user-friends",
        },
        {
          title: "Invite others",
          description: "Expand your network by inviting others to join.",
          color: "#9b59b6", // Purple
          btTo: "/invite-others",
          btLabel: "Invite",
          icon: "fas fa-envelope-open-text",
        },

        {
          title: "Explore communities",
          description: "Find communities that match your interests.",
          color: "#16a085", // Turquoise
          btTo: "/explore-communities",
          btLabel: "Explore",
          icon: "fas fa-users",
        },
        {
          title: "Customize your feed",
          description: "Tailor your feed to see relevant content.",
          color: "#e67e22", // Orange
          btTo: "/customize-feed",
          btLabel: "Customize",
          icon: "fas fa-rss",
        },
        {
          title: "Review privacy settings",
          description: "Ensure your privacy settings match your preferences.",
          color: "#2980b9", // Royal Blue
          btTo: "/privacy-settings",
          btLabel: "Review Settings",
          icon: "fas fa-cog",
        },*/
      ];

      return cards;
    },
  },
  methods: {
    save(cb) {
      this.$emit("save", cb);
    },
    generatePrices() {
      let prices = [100, 200, 300, 450, 650, 900, 1200, 1700, 2300, 3000, 4000, 5000, 6000, 7000, 8000, 9000];
      // for (let i = 1; i <= 10; i++) prices.push(i);
      // for (let i = 100; i <= 500; i += 100) prices.push(i);
      //for (let i = 500; i <= 50000; i += 500) prices.push(i);
      return prices;
    },
  },
};
</script>

<style>
.muted {
  opacity: 0.5;
}
</style>
